<template>
    <section class="content">
        <div class="container-fluid unselectable">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
                    <div class="card bg-light d-flex flex-fill">
                        <div class="card-header text-muted border-bottom-0">
                        Berita Terkini
                        </div>
                        <div class="card-body pt-0">
                        <div class="row">
                            <div class="col-12">
                            <h2 class="lead"><b>{{ berita.title }}</b></h2>
                            </div>
                        </div>
                        </div>
                        <div class="card-footer">
                        <div class="text-right">     
                            <router-link :to='{
                                path: "/"
                            }' class="btn btn-sm btn-secondary">
                            <i class="fas fa-arrow-left"></i> Ke Halaman Utama
                          </router-link>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card card-outline card-info">
                <div class="card-header">
                <h3 class="card-title">
                </h3>
                </div>
                    <div class="card-body">
                        <div class="content">
                            <div v-html="berita.konten"></div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { authFetch } from "@/libs/hxcore";
import $ from "jquery";
    export default {
    name: 'BeritaDetail',
    data: () => ({
      berita: {},
	  roles: "",
	  post_id: "",
    }),
    created: function () {
        let id = this.$route.params.id;
        this.loadBerita(id);
        // this.post_id = id;

		this.roles = this.$route.meta.roles;
    	console.log("load initial data", this.$route);
    },
    methods: {
        loadBerita(id){
			authFetch("/siswa/frontpage/berita_detail/" + id).then((res) => {
				res.json().then((json) => { 
                    this.berita = json;
				});
			});
        },
    },
    mounted(){
	const e = this.$refs;
	let self = this;

    $(document).ready(function() {
        $(".content").on("contextmenu", function(e) {
            return false;
        });
    });
    }
}
</script>
<style type="text/css" scoped>
.unselectable {
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
</style>